import React from 'react'
import {Box} from 'theme-ui'
import Img from 'gatsby-image'
import {getFluidGatsbyImage} from 'gatsby-source-sanity'
import clientConfig from '../../../client-config'

export default ({node}) => {
  if (!node || !node.asset || !node.asset._id) { return null }
  const fluidProps = getFluidGatsbyImage(
    node.asset._id,
    {maxWidth: 900},
    clientConfig.sanity
  )
  return (
    <Box pt='32px' pb='16px' sx={{m: '0 auto', maxWidth: '480px'}}>
      <figure>
        <Img fluid={fluidProps} alt={node.alt} />
      </figure>
    </Box>
  )
}
